// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dreaming-sonder-js": () => import("/opt/build/repo/src/pages/dreaming-sonder.js" /* webpackChunkName: "component---src-pages-dreaming-sonder-js" */),
  "component---src-pages-hot-soup-restaurant-js": () => import("/opt/build/repo/src/pages/hot-soup-restaurant.js" /* webpackChunkName: "component---src-pages-hot-soup-restaurant-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medium-js": () => import("/opt/build/repo/src/pages/medium.js" /* webpackChunkName: "component---src-pages-medium-js" */),
  "component---src-pages-quora-js": () => import("/opt/build/repo/src/pages/quora.js" /* webpackChunkName: "component---src-pages-quora-js" */),
  "component---src-pages-writing-js": () => import("/opt/build/repo/src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

